import { IContent } from '../content';

const BusinessHours: IContent = {
	title: {
		sk: `Otváracie hodiny`,
		en: `Opening hours`,
		de: `Öffnungszeiten`
	},
	content: {
		sk: `
		Pondelok - Sobota: 07:00 - 21:00
Nedeľa: 08:00 - 20:00
		`,
		en: `
		Monday - Saturday: 07 AM - 9 PM
Sunday: 08 AM - 8 PM
		`,
		de: `
		Montag - Samstag: 07 - 21 Uhr
Sonntag: 08 - 20 Uhr
		`
	},
	slug: {
		sk: 'otvaracie-hodiny',
		en: 'opening-hours',
		de: 'offnungszeiten'
	}
};

export default BusinessHours;
