import { IContent, RentingSpacesTablesDataFragment } from '../content';

const RentingSpaces: IContent = {
	title: {
		sk: `Prenájom priestorov`,
		en: `Renting spaces`,
		de: `Vermietung von Räumen`
	},
	content: {
		sk: `V prípade ak máte záujem o prenájom nájomnej jednotky v OC Hypka, prosím kontaktujte info@ochypka.sk. Cena dohodou.
		`,
		en: `In case you are interested in renting a rental unit at OC Hypka, please contact info@ochypka.sk. Price is negotiable.
		`,
		de: `Falls Sie Interesse an der Anmietung einer Mieteinheit im OC Hypka haben, kontaktieren Sie bitte info@ochypka.sk. Preis nach Vereinbarung möglich.
		`
	},
	slug: {
		sk: 'prenajom-priestorov',
		en: 'renting-spaces',
		de: 'vermietung-von-raumen'
	}
};

export default RentingSpaces;

export const RentingSpacesTableData: RentingSpacesTablesDataFragment[] = [
	{
		title: {
			sk: 'Kontajner',
			en: 'Container',
			de: 'Container'
		},
		description: {
			sk: 'Je možné mať v prenájme len 1 časť., vhodný aj ako showroom',
			en: 'Only 1 part can be rented, suitable also as a showroom',
			de: 'Es kann nur 1 Teil gemietet werden, auch als Showroom geeignet'
		},
		meters: '44,11'
	},
	{
		title: {
			sk: 'Kancelária na poschodí',
			en: 'Office on the upper floor',
			de: 'Büro im Obergeschoss'
		},
		description: {
			sk: 'Vhodný ako kancelárske priestory, vrátane sprchy a wc',
			en: 'Suitable as office space, including shower and toilet',
			de: 'Geeignet als Bürofläche, einschließlich Dusche und WC'
		},
		meters: '38'
	}
];
