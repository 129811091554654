/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable react/no-array-index-key */
import { createContext, Suspense, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
// interfaces
//  import { RouteWithChildrenInterface } from './RoutingInterface';
// constants
// import { isAuthenticated } from '@/ts/constants/Helpers';
// routing helpers
import { getDefaultLanguage } from './RoutingHelper';
// routes
import { getHomePageUrl } from './AppUrls';
// import { allRoutes } from '@/ts/routing/routingConstants/RoutesConfig';
// pages
import NotFoundPage from './NotFoundPage';
// components
import Layout from '../../components/layout';
import LoadingIcon from './LoadingIcon';
import allRoutes from './RoutesConfig';

export const LocaleContext = createContext({
	locale: ''
});

const LangRouter = () => {
	const { i18n } = useTranslation();
	const { pathname, search, hash } = useLocation();
	const navigate = useNavigate();
	const availableLocales = i18n.languages;
	const defaultLocale: string = getDefaultLanguage() ?? 'sk';
	const pathnameLocale = pathname.substring(1, 3).toLowerCase();
	const [locale, setLocale] = useState(defaultLocale);
	const loaderTimerRef = useRef<any>();
	const [isLoading, setIsLoading] = useState(true);
	document.body.dir = i18n.dir(i18n.language);

	useEffect(() => {
		loaderTimerRef.current = setTimeout(() => {
			setIsLoading(false);
			clearTimeout(loaderTimerRef.current);
		}, 300);
	}, []);

	useEffect(() => {
		if (availableLocales.includes(pathnameLocale)) {
			updateLocale(pathnameLocale);
		} else if (pathname === '/') {
			updateLocale(i18n.language);
		}

		window.scrollTo(0, 0);
	}, [pathname]);

	useEffect(() => {
		updateLocale(i18n.language);
	}, [i18n.language]);

	useEffect(() => {
		let lang = defaultLocale;

		if (availableLocales.includes(pathnameLocale)) {
			lang = pathnameLocale;
			setLanguageHandler(lang);
		} else if (pathname === '/') {
			setLanguageHandler(lang);
		}
	}, [locale]);

	const setLanguageHandler = (lang: string) => {
		i18n
			.changeLanguage(lang)
			.then(() => document.documentElement.setAttribute('lang', lang));
	};

	const updateLocale = (newLocale: string) => {
		const newPath = `/${newLocale}${pathname.substring(3)}`;

		if (locale !== newLocale) {
			if (
				newPath === `/${newLocale}/` ||
				newPath === `/${newLocale}` ||
				pathname === '/'
			) {
				navigate(getHomePageUrl(newLocale));
			} else {
				navigate(`${newPath}${hash}${search}`);
			}
			setLocale(newLocale);
		} else if (
			newPath === `/${newLocale}/` ||
			newPath === `/${newLocale}` ||
			pathname === '/'
		) {
			navigate(getHomePageUrl(newLocale));
		}
	};

	const renderRoutes = (routes: any) =>
		routes.map((route: any, index: number) => (
			<Route key={index} path={route.path(locale)} element={route.element}>
				{route.children && renderRoutes(route.children)}
			</Route>
		));

	if (isLoading) {
		return (
			<div className="loader-wrapper">
				<LoadingIcon />
			</div>
		);
	}

	return (
		<LocaleContext.Provider value={{ locale }}>
			<Routes>
				<Route
					path="/"
					element={
						<Suspense fallback={<>...</>}>
							<Layout />
						</Suspense>
					}
				>
					<Route path="*" element={<NotFoundPage />} />
				</Route>

				{renderRoutes(allRoutes)}
			</Routes>
		</LocaleContext.Provider>
	);
};

export default LangRouter;
